<template>
  <div>
    <!------------ START: Input group ------------>
    <div ref="inputGroup" class="input-group">
      <div
        class="condition-item form-control text-nowrap p-0"
        :class="[validationClass, isDisabled ? 'disabled' : '']"
      >
        <div class="overflow-x-auto">
          <!------------ START: Field input ------------>
          <autosize-input
            ref="field"
            v-model="$v.condition.field.$model"
            input-class="autosize-input py-2 pl-3 pr-2"
            :placeholder="$t('mapping.selectField')"
            :disabled="isDisabled"
            @input="onInput('field')"
            @focus="onFocus('field')"
            @keyup.esc="showVariables = !showVariables"
            @keydown.tab="showVariables = false"
          />
          <!------------ END: Field input ------------>
          <!------------ START: Operator input ------------>
          <autosize-input
            ref="operator"
            :value="selectedOperatorLabel"
            class="position-relative"
            :class="{ invisible: !condition.field }"
            input-class="autosize-input py-2"
            :placeholder="$t('mapping.selectOperator')"
            :disabled="isDisabled"
            readonly
            @focus="showOperators = true"
            @click="showOperatorsMenu"
            @keydown.tab="showOperators = false"
            @input="onInput('operator')"
          />
          <!------------ END: Operator input ------------>
          <!------------ START: Value input ------------>
          <autosize-input
            v-if="showValue"
            ref="value"
            v-model="computedValue"
            :input-class="'autosize-input py-2 pl-2 pr-3 ' + highlightClass"
            :placeholder="$t('mapping.selectValue')"
            :disabled="isDisabled || !selectedOperatorHasParameters"
            @input="onInput('value')"
            @focus="onFocus('value')"
            @keyup.esc="showVariables = !showVariables"
            @keydown.tab="showVariables = false"
          />
        </div>
        <!------------ END: Value input ------------>
      </div>
      <!------------ START: Return type ------------>
      <div class="input-group-append">
        <span
          v-b-popover.hover.html.top="returnTypeText"
          class="input-group-text border-left-0 bg-white px-2"
          :class="{ 'cursor-pointer': typeTogglePossible }"
          @click="toggleType"
        >
          <i :class="[returnTypeIcon]" />
          <i v-if="typeTogglePossible" class="fal fa-rotate toggle-empty" />
        </span>
      </div>
      <!------------ END: Return type ------------>
      <!------------ START: Clear button ------------>
      <div
        v-b-popover.hover.top="$t('formHelper.conditionClear')"
        class="input-group-append"
        :class="{ 'cursor-pointer': !isDisabled }"
        @click="clear"
      >
        <span class="input-group-text">
          <i class="fal fa-delete-left" />
        </span>
      </div>
      <!------------ END: Clear button ------------>
      <!------------ START: Nest button ------------>
      <div
        v-if="nestable"
        v-b-popover.hover.top="$t('formHelper.conditionNest')"
        class="input-group-append"
        :class="{ 'cursor-pointer': !isDisabled }"
        @click="nest"
      >
        <span class="input-group-text">
          <i class="fal fa-diagram-nested" />
        </span>
      </div>
      <!------------ END: Nest button ------------>
      <!------------ START: Remove button ------------>
      <div
        v-if="removable"
        v-b-popover.hover.top="$t('formHelper.conditionRemove')"
        class="input-group-append"
        :class="{ 'cursor-pointer': !isDisabled }"
        @click="remove"
      >
        <span class="input-group-text">
          <i class="fal fa-trash" />
        </span>
      </div>
      <!------------ END: Remove button ------------>
    </div>
    <!------------ END: Input group ------------>
    <!------------ START: Operator menu ------------>
    <div
      v-if="isMounted"
      v-show="showOperators"
      ref="operatorMenu"
      class="position-fixed bg-white shadow border"
      style="z-index: 22"
    >
      <v-list dense>
        <v-list-item
          v-for="(operator, i) in operatorsList"
          :key="i"
          class="justify-content-center"
          link
          @click="setOperator(operator.name)"
        >
          {{ operator.label }}
        </v-list-item>
      </v-list>
    </div>
    <!------------ END: Operator menu ------------>
    <!------------ START: Variables dropdown ------------>
    <VariablesDropdown
      v-model="showVariables"
      :filter="filter"
      :el="$refs.inputGroup"
      :input-el="activeInput.el"
      :use-curly-brackets="useCurlyBrackets"
      @select="setVariable"
    />
    <!------------ END: Variables dropdown ------------>
  </div>
</template>

<script>
import AutosizeInput from "vue-autosize-input";
import VariablesDropdown from "@/components/Tools/FormHelper/Components/VariablesDropdown/VariablesDropdown";
import {
  checkValueType,
  transformValue,
  typeOf
} from "@/components/Tools/FormHelper/Helper/functions";
import {
  arrayOperators,
  customRegex,
  returnTypeIcons
} from "@/components/Tools/FormHelper/Helper/constants";
import _ from "lodash";
import { noValueOperators } from "@/components/Tools/FormHelper/Helper/constants";

export default {
  name: "ConditionItem",
  components: { VariablesDropdown, AutosizeInput },
  inject: ["operators", "useCurlyBrackets"],
  props: {
    value: {
      type: Object,
      default: () => ({
        type: "condition",
        field: "",
        operator: "",
        value: "",
        valid: undefined
      })
    },
    removable: {
      type: Boolean,
      default: false
    },
    nestable: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMounted: false,
      showOperators: false,
      showVariables: false,
      returnType: "",
      highlightClass: "",
      activeInput: {
        name: "",
        el: undefined
      },
      operatorPosition: {
        x: 0,
        y: 0
      }
    };
  },
  validations() {
    return {
      condition: {
        field: { required: value => value !== "" },
        operator: { required: value => value !== "" },
        value: { required: value => value !== "" },
        valid: {}
      }
    };
  },
  computed: {
    condition: {
      get: function () {
        return _.cloneDeep(this.value);
      },
      set: function (condition) {
        this.$emit("input", condition);
      }
    },
    filter: function () {
      return "";
    },
    // Get correct form validation css class
    validationClass: function () {
      let classText = "";
      if (this.$v.condition.valid.$model) {
        // If field is dirty and invalid
        classText = "is-valid";
      } else if (this.$v.condition.valid.$model === false) {
        // Else if field is dirty and valid
        classText = "is-invalid";
      }
      // Else: if field is not dirty yet, set no class
      return classText;
    },
    computedValue: {
      get: function () {
        // Always get value as string to be editable
        return String(this.$v.condition.value.$model);
      },
      set: function (value) {
        // Set value casted
        this.$v.condition.value.$model = this.checkValue(value);
      }
    },
    typeTogglePossible: function () {
      let value = this.condition.value;
      let current = this.returnType,
        target = "";
      if (current !== "text" && current !== "variable") {
        // If current type is not text and not variable,
        // transformation to text is always possible
        target = "text";
      } else if (["true", "false", "null"].includes(value)) {
        // Value can be transformed to boolean/null
        target = value;
      } else if (!isNaN(value) && value !== "") {
        // Value can be transformed into number
        target = "number";
      } else if (
        arrayOperators.includes(this.$v.condition.operator.$model) &&
        value.includes(",")
      ) {
        target = "array";
      }
      // Return target
      return target;
    },
    // Get return type icon
    returnTypeIcon: function () {
      return returnTypeIcons[this.returnType];
    },
    // Get return type text based on current return type
    returnTypeText: function () {
      let prefix = this.$t("formHelper.value"),
        typeText = this.$t(
          "formHelper.returnTypes." + this.returnType.length
            ? this.returnType
            : "text"
        );
      return `${prefix}: <span class="font-italic">${typeText}</span>`;
    },
    operatorsList: function () {
      let list = [];
      this.operators.forEach(operator => {
        if (typeof operator === "object") {
          list.push(operator);
        } else {
          list.push({
            name: operator,
            label: operator,
            hasParameters: true
          });
        }
      });
      return list;
    },
    selectedOperatorLabel: function () {
      let operator = this.$v.condition.operator.$model;
      return operator
        ? this.operatorsList.find(o => o.name === operator)?.label ?? operator
        : "";
    },
    selectedOperatorHasParameters() {
      return (
        this.operatorsList.find(
          o => o.name === this.$v.condition.operator.$model
        )?.hasParameters ?? true
      );
    },
    showValue() {
      return (
        !noValueOperators.includes(this.condition.operator) &&
        (this.condition.operator ||
          (this.condition.operator && this.condition.field))
      );
    }
  },
  created() {
    document.addEventListener("click", this.toggleOperatorMenu);
    window.addEventListener("scroll", this.updateOperatorPositions);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.updateOperatorPositions);
    document.removeEventListener("click", this.toggleOperatorMenu);
  },
  mounted() {
    this.setReturnType(typeOf(this.condition.value, true));
    this.isMounted = true;
  },
  methods: {
    // Emit remove event
    remove() {
      if (this.isDisabled) {
        return;
      }
      this.onInput();
      this.$emit("remove");
    },
    // Clear condition
    clear() {
      if (this.isDisabled) {
        return;
      }
      this.$v.condition.field.$model = "";
      this.$v.condition.operator.$model = "";
      this.$v.condition.value.$model = "";
      this.$v.condition.$reset();
      this.onInput();
    },
    // Emit nest event
    nest() {
      if (this.isDisabled) {
        return;
      }
      this.$emit("nest");
    },
    onInput(name = "") {
      // Validate condition
      this.validate();
      this.condition = {
        field: this.$v.condition.field.$model,
        operator: this.$v.condition.operator.$model,
        value: this.$v.condition.value.$model,
        valid: this.$v.condition.valid.$model,
        type: "condition"
      };
      // Emit change event
      this.$emit("change", {
        value: name
          ? this.condition[name]
          : { field: "", operator: "", value: "" },
        valuePath: name
      });
    },
    onFocus(name) {
      // Set active input props
      this.activeInput.name = name;
      this.activeInput.el = this.$refs[name].$el;
      // Show variables dropdown
      this.showVariables = true;
    },
    setVariable(variable) {
      // Set variable
      this.$v.condition[this.activeInput.name].$model = variable;
      // Hide variables dropdown
      this.showVariables = false;
      // Update v-model
      this.onInput(this.activeInput.name);
    },
    setOperator(operator) {
      // Set operator
      this.$v.condition.operator.$model = operator;
      this.showOperators = false;
      // If selected operator has no parameters, clear value field
      if (!this.selectedOperatorHasParameters) {
        this.computedValue = "";
      }
      // Update v-model
      this.onInput("operator");
    },
    validate() {
      // Set valid with fallback undefined
      let valid = undefined;
      let c = this.$v.condition;
      if (c.field.required && c.operator.required && c.value.required) {
        // If all parts are set
        valid = true;
      } else if (c.field.$error || c.operator.$error || c.value.$error) {
        // Else if at least one part is dirty and invalid
        valid = false;
      }
      // Set valid state
      this.$v.condition.valid.$model = valid;
      return valid;
    },
    showOperatorsMenu() {
      this.showOperators = true;
      this.updateOperatorPositions();
    },
    updateOperatorPositions() {
      // Get current position of operator field to position menu
      let menu = this.$refs.operatorMenu;
      let rect = this.$refs.operator.$el.getBoundingClientRect();
      menu.style.left = Math.round(rect.x - 15) + "px";
      menu.style.top = Math.round(rect.y + rect.height) + "px";
    },
    toggleOperatorMenu(e) {
      // If click is outside the menu, close it
      if (
        !this.$refs.operatorMenu.contains(e.target) &&
        !this.$refs.operator.$el.contains(e.target)
      ) {
        this.showOperators = false;
      }
    },
    toggleType() {
      let targetType = this.typeTogglePossible;
      if (!targetType) {
        return;
      }
      this.setReturnType(targetType);
      this.$v.condition.value.$model = transformValue(
        this.$v.condition.value.$model,
        targetType
      );
      this.onInput("value");
    },
    checkValue(value) {
      if (this.returnType === "variable" && !customRegex.variable.test(value)) {
        // If return type is variable, but value is no variable, change to text
        this.setReturnType("text");
      } else if (
        this.returnType !== "variable" &&
        customRegex.variable.test(value)
      ) {
        // If value is variable, change return type to variable
        this.setReturnType("variable");
      }
      let valueTransformed = transformValue(value, this.returnType);
      let valid = checkValueType(valueTransformed, this.returnType);
      // If return type and value don't match, fallback to text
      if (!valid || value === "") {
        this.setReturnType("text");
        value = String(value);
      } else {
        value = valueTransformed;
      }
      return value;
    },
    setReturnType(type) {
      this.returnType = type;

      if (type === "number") {
        this.highlightClass = "highlight-number";
      } else if (["true", "false", "null"].includes(type)) {
        this.highlightClass = "highlight-bool-null";
      } else if (type === "variable") {
        this.highlightClass = "highlight-variable";
      } else if (type === "array") {
        this.highlightClass = "highlight-array";
      } else {
        this.highlightClass = "";
      }
    }
  }
};
</script>

<style lang="scss">
.autosize-input {
  outline: none;
}
.mw-none {
  max-width: none;
}
</style>
