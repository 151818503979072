<template>
  <!------------ START: Condition group ------------>
  <div
    ref="conditionGroup"
    class="condition-group position-relative"
    :style="`margin-left: ${indent}px`"
  >
    <!------------ START: Condition group operator ------------>
    <div class="condition-group-operator">
      <span
        class="group-operator position-absolute border text-muted d-flex align-items-center justify-content-center bg-white zindex-1"
        :class="{ 'cursor-pointer': !isDisabled }"
        :style="operatorStyle"
        @click="toggleOperator"
      >
        {{ operator }}
      </span>
    </div>
    <!------------ END: Condition group operator ------------>
    <!------------ START: Group children loop ------------>
    <div
      v-for="(child, i) in group.children"
      :key="i"
      class="condition-group-child mb-1"
    >
      <!------------ START: Group child ------------>
      <ConditionGroup
        v-if="child.type === 'group'"
        :key="childKey"
        :value="childData[i]"
        :is-nested="true"
        :is-disabled="isDisabled"
        class="condition-group"
        @input="val => (childData = { index: i, data: val })"
        @remove="removeChild(i)"
        @change="payload => onChange(i, payload)"
      />
      <!------------ END: Group child ------------>
      <!------------ START: Item child ------------>
      <ConditionItem
        v-else-if="child.type === 'condition'"
        :key="childKey"
        :value="childData[i]"
        :removable="hasSiblings || isNested"
        :nestable="!isNestable ? false : hasSiblings"
        :is-disabled="isDisabled"
        class="condition-item"
        @input="val => (childData = { index: i, data: val })"
        @nest="nest(i)"
        @remove="removeChild(i)"
        @change="payload => onChange(i, payload)"
      />
      <!------------ END: Item child ------------>
    </div>
    <!------------ END: Group children loop ------------>
    <!------------ START: Add condition ------------>
    <div
      class="form-control bg-transparent condition-add text-muted border-0 pl-1"
      :class="{ 'cursor-pointer text-hover-primary': !isDisabled }"
      @click="addChild"
    >
      + {{ $t("formHelper.condition") }}
    </div>
    <!------------ END: Add condition ------------>
    <!------------ START: Add condition line ------------>
    <div class="condition-line noninteractive">
      <svg
        :height="groupHeight"
        :width="indent"
        class="position-absolute top-0"
        :style="`left: -${indent}px;`"
      >
        <path
          :d="points"
          fill="none"
          stroke="var(--secondary)"
          stroke-width="1"
        />
      </svg>
    </div>
    <!------------ END: Add condition line ------------>
  </div>
  <!------------ END: Condition group ------------>
</template>

<script>
import ConditionItem from "@/components/Tools/FormHelper/Components/Condition/ConditionItem";
import $ from "jquery";
import _ from "lodash";

export default {
  name: "ConditionGroup",
  components: {
    ConditionItem,
    ConditionGroup: () =>
      import(
        "@/components/Tools/FormHelper/Components/Condition/ConditionGroup"
      )
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        type: "group",
        operator: "and",
        children: [],
        valid: undefined
      })
    },
    isNested: {
      type: Boolean,
      default: false
    },
    isNestable: {
      type: Boolean,
      default: true
    },
    operatorToggleable: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      indent: 20,
      groupHeight: 0,
      points: "",
      childKey: 1,
      defaultGroup: {
        type: "group",
        operator: "and",
        children: [],
        valid: undefined
      },
      defaultChild: {
        type: "condition",
        field: "",
        operator: "",
        value: "",
        valid: undefined
      }
    };
  },
  computed: {
    group: {
      get: function () {
        return _.cloneDeep(this.value);
      },
      set: function (group) {
        this.$emit("input", group);
      }
    },
    childData: {
      get: function () {
        return _.cloneDeep(this.group.children);
      },
      set: function (payload) {
        let group = Object.assign({}, this.group);
        group.children[payload.index] = payload.data;
        this.group = group;
      }
    },
    // Return if condition has more than one child
    hasSiblings: function () {
      return this.group.children.length > 1;
    },
    // Get operator label by value
    operator: function () {
      switch (this.group.operator) {
        case "and":
          return "&";
        case "or":
          return "or";
        default:
          return "";
      }
    },
    // Get operator css position styles
    operatorStyle: function () {
      let left = -this.indent - 10,
        top = this.groupHeight / 2 - 10;
      return `left: ${left}px; top: ${top}px; transform: translate(-${left}px, -${top}px);`;
    }
  },
  mounted() {
    // Init line
    this.updateLine();

    // Set operator to "and" if operator nesting is disabled
    if (!this.operatorToggleable) {
      let group = Object.assign({}, this.group);
      group.operator = "and";

      this.group = group;
    }
  },
  methods: {
    onChange(index, payload) {
      payload.valuePath = payload.valuePath
        ? `children.${index}.${payload.valuePath}`
        : "children." + index;
      this.$emit("change", payload);
    },
    // Add child to group
    addChild() {
      if (this.isDisabled) {
        return;
      }
      let group = Object.assign({}, this.group);
      group.children.push(this.defaultChild);
      this.group = group;
      // Force child elements to rerender
      this.childKey++;
      this.updateLineAsync();
    },
    // Nest child in subgroup
    nest(index) {
      // Get children
      let children = this.group.children;
      // Create subgroup
      let nestedGroup = Object.assign({}, this.defaultGroup);
      // Assign child to subgroup
      nestedGroup.children = [children[index]];
      // Set subgroup as child replacement
      let group = Object.assign({}, this.group);
      group.children[index] = nestedGroup;
      this.group = group;
      // Force child elements to rerender
      this.childKey++;
      this.updateLineAsync();
    },
    removeChild(index) {
      let group = Object.assign({}, this.group);
      // Splice child from children
      group.children.splice(index, 1);
      if (group.children.length === 0 && this.isNested) {
        // If this was the only child, remove this subgroup
        this.$emit("remove");
      } else if (
        group.children.length === 1 &&
        group.children[0].type === "group"
      ) {
        // Else if only child left is a subgroup, move children up
        group.children = group.children[0].children;
      }
      this.group = group;
      // Force child elements to rerender
      this.childKey++;
      this.updateLineAsync();
    },
    toggleOperator() {
      if (this.isDisabled || !this.operatorToggleable) {
        return;
      }
      let group = Object.assign({}, this.group);
      // Change operator after click
      switch (group.operator) {
        case "and":
          group.operator = "or";
          break;
        case "or":
          group.operator = "and";
          break;
        default:
          group.operator = "and";
          break;
      }
      this.group = group;
    },
    updateLineAsync() {
      this.$nextTick().then(() => this.updateLine());
    },
    updateLine() {
      // Update group height
      this.setGroupHeight();
      // Get condition group element
      let $conditionGroup = $(this.$refs.conditionGroup);
      // Set x, y & points defaults
      let x = 0,
        y = 0,
        points = [];
      // Get group children
      let children = $conditionGroup.find("> .condition-group-child");
      // Loop through children
      for (let index = 0; index < children.length; index++) {
        // Get child height
        let height = $(children[index]).height();
        // Update y to half of child's height
        y += height / 2;
        // Push points either moving or lining, depending on index
        points.push(`${index === 0 ? "M" : "L"}${x} ${y}`);
        // Line right
        x = this.indent;
        points.push(`${x} ${y}`);
        // Move back left
        x = 0;
        points.push(`M${x} ${y}`);
        // Line to full height of child
        y += height / 2 + 4;
        points.push(`L${x} ${y}`);
      }
      // Get add button
      let $add = $conditionGroup.find("> .condition-add");
      // Line half of it's height
      y += $add.outerHeight(true) / 2;
      points.push(`L${x} ${y}`);
      // Line right
      x = this.indent;
      points.push(`${x} ${y}`);
      // Set points by concatenating with space
      this.points = points.join(" ");
    },
    setGroupHeight() {
      // Set current height of html element
      this.groupHeight = this.$refs.conditionGroup.clientHeight;
    },
    validate() {
      let valid = true;
      let group = Object.assign({}, this.group);
      group.children.forEach(child => {
        child.valid === false ? (valid = false) : "";
      });
      group.valid = valid;
      this.group = group;
      return valid;
    }
  }
};
</script>

<style lang="scss">
.group-operator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
</style>
