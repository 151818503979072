<template>
  <div ref="inputGroup">
    <div class="input-group">
      <b-input
        ref="value"
        class="form-control"
        :value="directInputValue"
        @change="onChange"
        @focus="onFocus('value')"
        @keyup.esc="showVariables = !showVariables"
        @keydown.tab="showVariables = false"
      />
      <div
        v-b-popover.hover.top="$t('formHelper.conditionClear')"
        class="input-group-append"
        @click="directInputValue = null"
      >
        <span class="input-group-text">
          <i class="fal fa-delete-left" />
        </span>
      </div>
    </div>
    <!------------ START: Variables dropdown ------------>
    <VariablesDropdown
      v-model="showVariables"
      filter=""
      :el="$refs.inputGroup"
      :input-el="activeInput.el"
      @select="setVariable"
    />
    <!------------ END: Variables dropdown ------------>
  </div>
</template>

<script>
import VariablesDropdown from "@/components/Tools/FormHelper/Components/VariablesDropdown/VariablesDropdown.vue";

export default {
  components: { VariablesDropdown },
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showVariables: false,
      activeInput: {
        name: "",
        el: undefined
      }
    };
  },
  computed: {
    directInputValue: {
      get: function () {
        return this.value;
      },
      set: function (newVal) {
        this.onChange(newVal);
        this.$emit("input", newVal);
      }
    }
  },
  methods: {
    onChange(payload) {
      this.$emit("change", payload);
    },
    setVariable(variable) {
      // Set variable
      this.directInputValue =
        this.directInputValue === null
          ? variable
          : (this.directInputValue += variable);
      // Hide variables dropdown
      this.showVariables = false;
      this.$forceUpdate();
    },
    onFocus(name) {
      // Set active input props
      this.activeInput.name = name;
      this.activeInput.el = this.$refs[name].$el;
      // Show variables dropdown
      this.showVariables = true;
    }
  }
};
</script>
