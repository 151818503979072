<template>
  <div>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper :field="field">
      <template v-if="!field.disableDirectInput" #label-append>
        <b-form-checkbox v-model="directInput" switch>
          <span class="text-muted">{{ $t("formHelper.directInput") }}</span>
        </b-form-checkbox>
      </template>
      <!------------ START: Condition root group ------------>
      <ConditionGroup
        v-if="isMounted && !directInput"
        :key="key"
        v-model="value"
        :is-disabled="isDisabled"
        :is-nestable="!field?.disableNesting"
        :operator-toggleable="!field?.disableToggleableOperator"
        :field="field"
        @change="onChange"
      />
      <DirectInput
        v-else-if="isMounted"
        v-model="value.children[0].value"
        @change="onChange"
      ></DirectInput>
      <!------------ END: Condition root group ------------>
      <!------------ START: Condition rendered ------------>
      <div v-if="!directInput" class="text-muted">
        <span class="mr-1">{{ $t("formHelper.condition") }}:</span>
        <code>{{ conditionRendered }}</code>
      </div>
      <!------------ END: Condition rendered ------------>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
  </div>
</template>

<script>
import {
  base,
  eagerValidation
} from "@/components/Tools/FormHelper/Helper/mixins";
import { defaultOperators } from "@/components/Tools/FormHelper/Helper/constants";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import ConditionGroup from "@/components/Tools/FormHelper/Components/Condition/ConditionGroup";
import { renderConditions } from "@/components/Tools/FormHelper/Helper/functions";
import _ from "lodash";
import DirectInput from "@/components/Tools/FormHelper/Components/Condition/DirectInput.vue";

export default {
  components: { DirectInput, ConditionGroup, FieldWrapper },
  mixins: [base, eagerValidation],
  provide: function () {
    return {
      operators: this.field.operators ?? defaultOperators,
      useCurlyBrackets: this.field.useCurlyBrackets ?? true
    };
  },
  props: {},
  data() {
    return {
      directInput: false,
      isMounted: false,
      key: 1,
      defaultGroup: {
        type: "group",
        operator: "and",
        children: [
          {
            type: "condition",
            field: "",
            operator: "",
            value: "",
            valid: undefined
          }
        ],
        valid: undefined
      }
    };
  },
  computed: {
    value: {
      get: function () {
        let value = this.defaultValue;
        // Check if value is of type condition group
        if (
          typeof value !== "object" ||
          Array.isArray(value) ||
          value?.type !== "group"
        ) {
          let condition = this.defaultGroup;
          if (typeof value === "string") {
            condition.children[0].value = value;
          }
          value = condition;
        }
        // Return value
        return value;
      },
      set: function (value) {
        if (value?.children?.[0].operator === "") {
          value.children[0].value = null;
        }
        // Trigger input event with new value
        this.onInput(value);
      }
    },
    operators: function () {
      return this.field.operators ?? defaultOperators;
    },
    operatorsList: function () {
      let list = [];
      this.operators.forEach(operator => {
        if (typeof operator === "object") {
          list.push(operator);
        } else {
          list.push({
            name: operator,
            label: operator,
            hasParameters: true
          });
        }
      });
      return list;
    },
    conditionRendered: function () {
      return renderConditions(this.value, this.operatorsList);
    }
  },
  mounted() {
    this.setDefaultValue();
    this.validate();
    this.isMounted = true;
  },
  created() {
    if (this.field?.loadFormatter) {
      this.$emit("load-formatter", {
        type: this.field?.loadFormatter,
        field: this.field,
        value: this.computedValue
      });
    }
  },
  methods: {
    setDefaultValue() {
      // set directInput if set
      let child = this.value.children[0];
      if (
        typeof child.value === "string" &&
        child.value.length > 0 &&
        !child.operator &&
        !child.field
      ) {
        this.directInput = true;
        return;
      }
      // Set default group object to prevent errors id necessary
      if (
        typeof this.defaultValue !== "object" ||
        this.defaultValue?.type !== "group"
      ) {
        this.value = this.defaultGroup;
      }
    },
    validate() {
      // Validate whole condition object
      let valid = this.validateGroup(this.value);
      // If condition is same as default group (one empty condition) it's not invalid
      if (!valid && _.isEqual(this.value, this.defaultGroup)) {
        this.value.children[0].valid = undefined;
        valid = true;
      }
      this.key++;
      return valid;
    },
    validateGroup(group) {
      // Set valid as true
      let valid = true;
      // Loop through children
      group.children?.forEach(child => {
        // Validate child based on their type, either condition or group
        let childValid =
          child.type === "condition"
            ? this.validateCondition(child)
            : this.validateGroup(child);
        // If child is not valid, update local variable
        if (childValid === false) {
          valid = false;
        }
      });
      // Return valid state
      return valid;
    },
    validateCondition(condition) {
      // Set valid true if all three parts of condition are filled
      let valid = ![
        condition.field,
        condition.operator,
        condition.value
      ].includes("");
      // Set valid state
      this.$set(condition, "valid", valid);
      // Return valid state
      return valid;
    },
    onChange(payload) {
      if (this.directInput) {
        if (payload === null || payload.length) this.value = payload;
        return;
      }
      payload.name = this.field.name;
      payload.old = _.get(this.value, payload.valuePath);
      this.$emit("change", payload);
      // Execute onChange function if set
      if (typeof this.field.onChange === "function") {
        this.field.onChange();
      }
    }
  }
};
</script>

<style lang="scss">
.condition-item {
  > .overflow-x-auto {
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
